export const PHONE_MASK = '+\\3\\7\\5\\ (99) 999 99 99'
export const PAST_BUG_PHONE = '+375 (+375'
export const PAST_BUG_8_PHONE = '+375 (+8'

export const PHONE_TITLE = '+375'

export const PHONE_MATCHES_REG =
  /\+375\s\((25|29|33|44)\)\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}/
export const PHONE_MATCHES_ERR =
  'Проверьте код мобильного оператора!\n Доступные коды операторов: 25, 29, 33, 44 \n Пример: +375 (XX) XXX XX XX'
