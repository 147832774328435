import useAppContext from 'app/store/useAppContext'
import { setIsAffirmationModal, setIsGiftModal } from 'app/store/actions'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'
import GiftDesktopImg from 'shared/assets/images/gift/GiftDesktopImg.webp'
import GiftMobileImg from 'shared/assets/images/gift/GiftMobileImg.webp'
import GiftIcon from 'shared/assets/icons/GiftIcon.webp'

import cls from 'features/gift-modal/ui/GiftModal.module.scss'
import { useIsMobileScreen } from 'shared/utils/hooks'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'
import { getCurrentDate } from 'shared/utils/functions'

const customGiftModalStyles = {
  content: {
    margin: 0,
    padding: 0,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const customGiftMobileModalStyles = {
  content: {
    width: '100vw',
    height: `calc(100vh - 100px)`,
    maxHeight: '100vh',
    margin: '67px 0 0 0',
    overflow: 'auto',
    backgroundColor: 'white',
  },
}

export const GiftModal = () => {
  const {
    store: {
      isMobileSmall,
      modals: { isGiftModal, isAffirmationModal, isCookiesModal },
    },
    dispatch,
  } = useAppContext()

  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  const isOpenGiftModal = isGiftModal && !isAffirmationModal && !isCookiesModal

  const currentDate = getCurrentDate()

  const isMobile400 = useIsMobileScreen(400)

  const isMobileModalOpen = isMobileSmall && isGiftModal
  const customStyles = isMobileModalOpen
    ? customGiftMobileModalStyles
    : customGiftModalStyles

  const closeModal = () => {
    dispatch(setIsGiftModal(false))
  }
  const openAffirmationModal = () => {
    dispatch(setIsAffirmationModal(true))
  }
  const onAccept = () => {
    closeModal()

    openAffirmationModal()
  }

  return (
    <>
      <Modal
        isOpen={isOpenGiftModal && !isRegisteredPage}
        onRequestClose={closeModal}
        isTitle={false}
        style={customStyles}
        iconClass={cls.modal__close_icon}
        isCloseIcon={!isMobileModalOpen}
        content={
          <div
            className={classNames(
              cls.modal__container,
              isMobileModalOpen && cls.modal__container_modal,
            )}
          >
            {isMobile400 ? (
              <img
                className={cls.modal__image}
                src={GiftMobileImg}
                alt="GiftMobileImg"
              />
            ) : (
              <img
                className={cls.modal__image}
                src={GiftDesktopImg}
                alt="GiftDesktopImg"
              />
            )}

            <div className={cls.modal__content}>
              <h2 className={cls.modal__title}>
                Получите пакет акций при открытии счета
              </h2>
              <p className={cls.modal__desc}>
                Зарегистрируйте инвестиционный счет до {currentDate}. Пополните
                его на минимальную сумму и получите дополнительный пакет акций!
              </p>
              <Button
                className={cls.button}
                onClick={onAccept}
                iconPath={GiftIcon}
              >
                Получить акции
              </Button>
            </div>
          </div>
        }
      />
    </>
  )
}
